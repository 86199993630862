<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2 text-primary">Paramètres Avancés</h6>
    </b-row>
    <b-form>
      <b-row>
        <b-col sm="4">
          <b-form-group
            label="Longueur d'un Compte Général"
            label-for="length_account_gen"
          >
            <b-form-input
              v-model="length_account_gen"
              placeholder="15"
              name="length_account_gen"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Longueur d'un Compte Analytique"
            label-for="length_account_analytic"
          >
            <b-form-input
              v-model="length_account_analytic"
              placeholder="15"
              name="length_account_analytic"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Repport à nouveau" label-for="repport_to_new">
            <v-select
              v-model="repport_to_new"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="repportNewOptions"
              :reduce="(val) => val.value"
              input-id="repport_to_new"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    advancedData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      length_account_gen: "",
      length_account_analytic: "",
      repport_to_new: "",
      repportNewOptions: [
        { label: "Solde", value: "solde" },
        { label: "Détail", value: "detail" },
        { label: "Aucun", value: "aucun" },
      ],
      errors: [],
    };
  },
  methods: {
    async postCorporationSettingInformationForm() {
      // for the web app //
      let formData = new FormData();
      formData.append("length_account_gen", this.length_account_gen);
      formData.append("length_account_analytic", this.length_account_analytic);
      formData.append("repport_to_new", this.repport_to_new);
      //
      await myApi
        .post("update_corporation_infos", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}
</style>
